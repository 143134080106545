@import '../global/variables.less';

.logoWrapper {
  .logo {
    width: 33px;
    height: 33px;
  }
}
.user-navbar-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid @border-color;
  justify-content: space-between;
  padding: 0 70px;
  width: 100%;
  background-color: @White-color;
  @media only screen and (max-width: 600px) {
    padding: 0 30px;
  }
}
.user-navbar-container {
  .center {
    .ant-menu {
      color: @text-color;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      background: none;
      display: flex;
      justify-content: space-between;
    }
  }
}

.user-navbar-container {
  .center {
    .ant-menu-horizontal {
      border-bottom: none;
    }
  }
}

.user-navbar-container > .center > .ant-menu-horizontal > .ant-menu-item {
  line-height: 51px;
  border-bottom: none;
}

.user-navbar-container > .center > .ant-menu-horizontal > .ant-menu-item:hover {
  border-top: 4px solid @primary-color;
  margin-top: -5px;
}

.user-navbar-container
  > .center
  > .ant-menu-horizontal
  > .ant-menu-submenu
  .ant-menu-submenu-title:hover {
  background-color: none;
}

.user-navbar-container > .center > .ant-menu-submenu-title:hover {
  color: @primary-color;
}

.user-navbar-container > .center > .ant-menu-horizontal > .ant-menu-item a {
  color: @text-color;
}

.user-navbar-container
  > .center
  > .ant-menu-horizontal
  > .ant-menu-item
  a:hover {
  color: @primary-color;
}

.user-navbar-container
  > .center
  > .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected {
  border-top: 4px solid @primary-color;
  margin-top: -5px;
  color: @primary-color;
}

.user-navbar-container
  > .center
  > .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-active {
  border-bottom: none;
  color: @primary-color;
}

.user-navbar-container
  > .center
  > .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected
  a {
  color: @primary-color;
}

.user-navbar-container {
  .center {
    .ant-menu {
      background: none;
    }
  }
}

.defaultLogo {
  &.ant-avatar-image {
    background: @light-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @border-color;
  }
  &.ant-avatar > img {
    display: block;
    width: 50%;
    height: 50%;
    object-fit: cover;
    margin-left: 1px;
    margin-bottom: 1px;
  }
}

.user-navbar-container {
  .right {
    .ant-menu-horizontal {
      border-bottom: none;
    }
  }
}

.user-navbar-container {
  .right {
    .ant-menu-horizontal {
      line-height: unset;
      .ant-menu-submenu {
        border-bottom: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.user-navbar-container {
  .right {
    .ant-menu-horizontal:not(.ant-menu-dark) {
      .ant-menu-submenu:hover {
        border-bottom: none;
      }
    }
  }
}

.user-navbar-container {
  .right {
    .ant-menu-submenu-title:hover {
      color: @primary-color;
      background-color: none;
    }
  }
}

.user-navbar-container {
  .right {
    .ant-menu-horizontal {
      .ant-menu-item {
        a:hover {
          color: @primary-color;
          border-bottom: none;
        }
      }
    }
  }
}

.user-navbar-container {
  .logo {
    margin-top: 12px;
    margin-bottom: 11px;
    margin-left: 0;
    margin-right: 0;
    width: 33px;
    height: 33px;
  }
}
.responsive-menu {
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}
.menu {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.mobile-nav {
  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.iconBtn {
  margin-left: 15px;
}
