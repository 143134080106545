@import '../../styles/global/variables.less';
.ant-card {
  background-color: @White-color;
  border-radius: 8px;
  .ant-card-body {
    padding: 16px 16px;
    text-align: center;
  }
  .ant-card-actions {
    padding: 0px 16px;
    border-radius: 0px 0px 8px 8px;
    background-color: @light-color;
  }
  .ant-card-head {
    padding: 0 16px;
  }
}
.ant-card-bordered {
  border: 1px solid @border-color !important;
}

.sharingLink.ant-card {
  margin-top: 48px;
  box-shadow: 0px 10px 30px rgba(3, 8, 70, 0.15);
  width: 358px;
  transition: all 0.3s;
  @media only screen and (max-width: 380px) {
    width: 310px;
    transition: all 0.3s;
  }
  .ant-typography {
    margin-bottom: 0;
  }
  .ant-card-body {
    padding: 8px 8px 8px 16px;
  }
}
