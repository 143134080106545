@import '../../styles/global/variables.less';

.ant-modal-content {
  .ant-modal-body {
    padding: 16px;
  }
  .ant-modal-footer {
    padding: 12px;
  }
  .ant-modal-header {
    padding: 16px;
  }
}
