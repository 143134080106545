//CUSTOM STYLE CLASSES START
.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.ant-col-17 {
  max-width: 100%;
}

.ant-col-16 {
  max-width: 100%;
}

//margin & padding style
.m-0 {
  margin: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-8 {
  margin-right: 8px;
}

.p-0 {
  padding: 0;
}

//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}

// flex-box related style

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.fill-width {
  width: 100%;
}

//width classes

//cursor
.pointer {
  cursor: pointer;
}

//cursor

//border style
.b-0 {
  border: none;
}

//border style

//CUSTOM STYLE CLASSES END
/* font style */

.fs-15 {
  font-size: 15px;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-50 {
  font-size: 50px !important;
}

/* margin style */

.m-0 {
  margin: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-130 {
  margin-left: 130px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.ml-180 {
  margin-left: 180px !important;
}

.ml-200 {
  margin-left: 200px !important;
}

.ml-210 {
  margin-left: 210px !important;
}

.ml-290 {
  margin-left: 290px !important;
}

/* padding style */
.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.p-150 {
  padding: 150px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pb-400 {
  padding-bottom: 400px !important;
}

.pl-400 {
  padding-left: 400px !important;
}

.pr-400 {
  padding-right: 400px !important;
}

/* width style */

.w-50 {
  width: 50px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400 !important;
}

/* height style */

.h-auto {
  height: auto !important;
}

.h-100vh {
  height: 100vh;
}

.h-50 {
  height: 50px;
}

/* color style  */
.color-white {
  color: white !important;
}

.color-grey {
  color: grey !important;
}

.color-teal {
  color: teal !important;
}

.color-999 {
  color: #999 !important;
}

.color-red {
  color: red !important;
}

/* background color style */
.bg-color-00c5e9 {
  background-color: #00c5e9 !important;
}

.bg-color-white {
  background-color: white !important;
}

/* floar style */
.float-right {
  float: right !important;
}

/* display style */
.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

/* alignment style */
.text-start {
  text-align: start !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

/* zIndex style */
.zidx {
  z-index: 100 !important;
}

/* cursor style */
.pointer {
  cursor: pointer !important;
}

.ant-btn {
  line-height: 24px;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 24px;
  height: auto;
}

.ant-btn-round.ant-btn-sm {
  line-height: 24px;
  font-weight: 500;
  font-size: 12px;
  padding: 3px 24px;
  height: auto;
}

.square.ant-btn {
  line-height: 20px;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 16px;
  height: auto;
  border-radius: 4px;
}

.ant-btn-danger:hover {
  color: @danger-text;
  background: #fff1f1;
  border-color: #fff1f1;
}

.ant-btn-link {
  color: @text-color;
}

.ant-btn-link:hover {
  color: @text-color;
}

.textDark {
  color: @text-color-dark;
}

.textLight {
  color: @text-color;
}

.ant-modal-content {
  border: 1px solid @border-color;
  border-radius: 8px;
}

.ant-form-item-control-input {
  min-height: unset;
}

.ant-input-group-wrapper .ant-input-group-addon {
  text-align: left;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: transparent;
  border-right-width: 0px !important;
  outline: 0;
  box-shadow: none;
}

.ant-input-group-addon
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 0px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px;
}

.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: @text-color;
}

.ant-form-item-explain {
  text-align: center;
}

.upload {
  text-align: center;
}

.link-card {
  width: 100%;
  min-width: 500px;
  max-width: 500px;
  margin-top: 16px;
  transition: all 0.3s;
  border: none !important;

  @media only screen and (max-width: 1050px) {
    min-width: 420px;
    transition: all 0.3s;
  }

  @media only screen and (max-width: 471px) {
    min-width: 330px;
    transition: all 0.3s;
  }
}

.add-link-card {
  width: 100%;
  min-width: 500px;
  max-width: 500px;
  margin-top: 16px;
  transition: all 0.3s;

  @media only screen and (max-width: 1050px) {
    min-width: 420px;
    transition: all 0.3s;
  }

  @media only screen and (max-width: 471px) {
    min-width: 330px;
    transition: all 0.3s;
  }
}

.forgot-pwd-card {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  flex-direction: column !important;
}

.content.ant-layout-content {
  margin: 0px 55px 0px 0px;

  @media only screen and (max-width: 920px) {
    margin: 0px;
  }
}

.modal {
  transition: all 0.3s;

  @media only screen and (max-width: 623px) {
    min-width: 300px;
    transition: all 0.3s;
  }

  @media only screen and (max-width: 471px) {
    min-width: 220px;
    transition: all 0.3s;
  }

  :last-child {
    margin-bottom: 0px;
  }
}

.ant-picker-ok.ant-btn {
  padding: 3px 24px;
}

.ant-picker-footer > .ant-picker-ranges > .ant-picker-ok > .ant-btn {
  padding: 2px 18px;
}

.tabs {
  width: 70%;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
}

.ant-card {
  border-radius: 8px;
}

.btn-switch {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.phoneAvatar {
  &.ant-avatar-string {
    line-height: 54px;
  }
}

.shareLayout {
  height: 100vh;
  overflow-y: auto;
}

.ant-upload-list-item {
  height: 30px;
}

.ant-result {
  padding: 10px;
}

// themeList card
.themeCards {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid @border-color;
}

.avatarBorder.ant-avatar {
  border: 2px solid @White-color;
  filter: drop-shadow(0px 10px 30px rgba(3, 8, 70, 0.15));
}

.logo-phone {
  width: 16px;
  height: 16px;
}

// dashboard

.ant-tabs-tab {
  padding: 0px 0px 12px;
}

.ant-form-item {
  margin-bottom: 12px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  font-size: 12px;
}

.hover-card {
  position: relative;
}

.img-blur:hover {
  filter: blur(6px);
  -webkit-filter: blur(6px);
}

.gradient-blur:hover {
  filter: blur(6px);
  -webkit-filter: blur(6px);
}

.hover-Edit {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.hover-delete {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.hover-card:hover .hover-Edit {
  opacity: 1;
}

.hover-card:hover .hover-delete {
  opacity: 1;
}

.modal-title.ant-typography {
  font-size: 16px;
  font-weight: 500;
}

.ant-input[type='color'] {
  height: 30px;
  width: 80px;
}

.background-preview {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: @White-color;
  background-color: @black-color;
  background-size: 100% 100% !important;
}

.background-delete {
  position: absolute;
  top: 60%;
  left: 47%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.background-delete:hover {
  opacity: 1;
}

.background-preview:hover .background-delete {
  opacity: 1;
}

.fonts-modal {
  text-align: center;
  width: 120px;
  cursor: pointer;
}

.selected-font-border {
  border: 1px solid @primary-color !important;
}

.selected-theme-border {
  border: 2px solid @primary-color !important;
  border-radius: 8px;
}

.selected-bg-border {
  border: 2px solid @primary-color !important;
  border-radius: 8px;
}

.background-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gradient {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.@{class-prefix}-text-ellipsis {
  overflow: hidden !important;
  white-space: nowrap !important;
  display: block !important;
  text-overflow: ellipsis !important;
}

.ant-table-pagination.ant-pagination {
  margin: 16px;
}

.delete_icon {
  font-size: 18px;
  color: @danger-color;
}

.logo_img {
  width: 110px;
}

.lynk-logo {
  width: 350px;
  height: 150px;

  @media screen and (max-width: @screen-md-max) {
    width: 200px;
    height: 100px;
  }
}

.apply-btn {
  opacity: 0;
  transition: opacity 0.5s ease;
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply-btn:hover {
  opacity: 1;
}

.theme-img {
  position: relative;
}

.theme-btn {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drag {
  cursor: pointer;
  padding: 40px 0px;
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere !important;
}

.phone-content-card {
  & .ant-card-head-title {
    white-space: pre-wrap !important;
  }
}

.ant-popover-inner-content {
  max-width: 400px;
  text-align: justify;
}
