@import '../../styles/global/variables.less';

.ant-input-group-wrapper {
  .ant-input-group-addon {
    min-width: 100px;
    max-width: 100px;
    color: @text-color;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    background-color: transparent;
    border: 1px solid @border-color;
    border-radius: 4px;
    padding: 0px 12px;
  }
  .ant-input {
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    border: 1px solid @border-color;
    border-radius: 4px;
  }
}
