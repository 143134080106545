@import '../../styles/global/variables.less';

.square.ant-btn {
  padding: 5px @size-10+6;
}
.iconBtn.ant-btn {
  padding: 0px;
  border-radius: 4px;
  padding: 5px 0px;
  font-size: 16px;
}
.helpButton.ant-btn {
  box-shadow: 0px 10px 30px rgba(3, 8, 70, 0.15);
  position: fixed;
  left: 71px;
  bottom: 16px;
  height: 32px;
  width: 71px;
  padding: 0;
}

.helpButton.ant-btn > .helpIcon {
  margin-right: 4px;
}
.btnPrimary.ant-btn {
  &:hover {
    background-color: @btn-hover-primary;
    border-color: @btn-hover-primary;
    color: @White-color;
  }
}
.btnSecondary.ant-btn {
  background-color: @secondary-color;
  border-color: @secondary-color;
  color: @White-color;
  &:hover {
    background-color: @btn-hover-secondary;
    border-color: @btn-hover-secondary;
    color: @White-color;
  }
  &:focus {
    background-color: @secondary-color;
    border-color: @secondary-color;
    color: @White-color;
  }
}
.btnLight.ant-btn {
  background-color: @light-color;
  border-color: @light-color;
  color: @text-color;
  &:hover {
    background-color: @btn-hover-light;
    border-color: @btn-hover-light;
    color: @text-color;
  }
  &:focus {
    background-color: @light-color;
    border-color: @light-color;
    color: @text-color;
  }
}
.btnLink.ant-btn-link {
  font-size: @h6-font-size;
}
