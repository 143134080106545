@import '../global/variables.less';

.logoText.ant-typography {
  color: @text-color-dark;
  font-weight: @font-weight-medium;
  font-size: @h1-font-size;
  line-height: @headings-line-height;
  margin-bottom: 0px;
  margin-left: 5px;
}
.passwordText.ant-typography {
  color: @text-color-dark;
  font-weight: @font-weight-medium;
  font-size: @h5-font-size;
  line-height: @headings-line-height;
  margin-bottom: 0px;
  margin-left: 5px;
}
.forgot {
  text-align: center;
}
