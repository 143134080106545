@import '../styles/main.less';

.contentCentered {
  text-align: center;
}

.logo {
  width: 40px;
  height: 40px;
  float: left;
  margin-top: 5px;
  margin-left: 30px;
  margin-right: 20px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.layoutContainer {
  margin-left: 250px;
  margin-right: 55px;
}

.phoneContainer.ant-col {
  background: #f2f4f7;
  border: 1px solid #dde1ee;
  height: calc(~'100vh - 57px');
  border-top: 0px;
  width: 390px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 920px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-top: 36px;
  }

  .device {
    height: 504px;
    width: 252px;
    border: 10px solid black;
    border-radius: 30px;
    filter: drop-shadow(10px 20px 44px rgba(25, 32, 39, 0.3));
    background-size: 100% 100% !important;

    @media only screen and (max-width: 700px) {
      align-self: center;
    }
  }
}

.device-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

* {
  -ms-overflow-style: none;
}
.comingSoon {
  height: 600px;
  width: 600px;
  justify-content: center;
}

.instagram {
  display: inline-block;
  width: 250px;
  height: 250px;
  text-align: center;
  border-radius: 40px;
  color: #fff;
  font-size: 220px;
  line-height: 250px;
  vertical-align: middle;
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
}

.container-fluid {
  margin: 0 auto;
  max-width: 900px;
  margin-left: unset !important;
  margin-right: unset !important;

  @media only screen and (max-width: 1300px) {
    max-width: 520px;
  }
}

.main-container {
  height: 100vh;

  @media screen and (max-width: @screen-md-max) {
    height: 70vh;
  }
}

.login-container {
  margin: 0 40px;
  max-width: 1600px;
  padding: 90px 15px;

  @media screen and (max-width: @screen-md-max) {
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto !important;
  }

  .login-title {
    @media screen and (max-width: @screen-md-max) {
      font-size: 22px;
    }
  }

  .login-text {
    @media screen and (max-width: @screen-md-max) {
      font-size: 14px;
    }
  }
}

.vertical-divider {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.horizontal-divider {
  border-top: rgba(0, 0, 0, 0.5) !important;
  margin: 0px !important;
  padding: 0px 30px !important;
  width: 70%;
}

.home-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0px 15px;
}

.quote {
  opacity: 0.7;
  border-radius: 8px;
  margin-top: 20px;
  width: 800px;
  max-width: 80%;
}

.scroll-hidden-sharable-link {
  overflow-y: auto;
  height: 100vh;
}

.scroll-hidden-phone {
  overflow-y: auto;
  height: 420px;
  margin-bottom: 10px;
}

.avatar-shareablelink {
  color: #f56a00;
  background-color: #fde3cf;
  margin-top: 50px;
}

.phone-mylink {
  align-self: baseline;
  align-content: center;
}

.card-font {
  text-align: center;
  margin-top: 50px;
  width: 170px;
}

.home-page-title {
  font: 50px sans-serif;
  text-align: center;
}

.login-registration-page {
  width: 500px;
}

/*Appearance tab*/
.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-appearance {
  width: 120px;
  margin-top: 20px;
}

.font-appearance {
  font-family: monospace;
  color: #030846;
  font-size: 25px;
  margin-bottom: 0;
}

.profile-appearance {
  width: 500px;
}

.appearance-card {
  width: 170px;
  margin-top: 50px;
}

.themes-card {
  margin-top: 50px;
  width: 170px;
  height: 250px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

/*forget password*/
.forgetpassword-card {
  width: 500px;
}

/* linear-gradient */
.signIn-gradient {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

.signIn-gradient:hover {
  background: linear-gradient(
    180deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

.bgList-card {
  margin-top: 50px;
  width: 170px;
  height: 250px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.fontList-card {
  text-align: center;
  width: 170px;
  cursor: pointer;
}

.ant-popover-buttons .ant-btn {
  padding: 2px 24px;
}

.ant-popover-message > .anticon {
  position: absolute;
  top: 7px !important;
}

.ant-menu-vertical .ant-menu-item {
  margin: 0px !important;
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin: 0px !important;
}

.vertical-menu.ant-menu-root.ant-menu-vertical {
  border: 0px;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #dde1ee;
  border-radius: 8px;
}

.ant-table-tbody > tr > td {
  border-bottom: 0px !important;
}

.ant-table-thead > tr > th {
  padding: 16px 16px !important;
}

.ant-table.ant-table-bordered.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr.ant-table-placeholder
  > td {
  border-right: 0;
  border-radius: 8px;
}

.table {
  border-radius: 8px;
  border: 1px solid #dde1ee;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
}
