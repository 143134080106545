@import '../global/variables.less';

.title.ant-typography {
  color: @text-color-dark;
  font-weight: @font-weight-medium;
  font-size: @font-size-lg;
  line-height: @headings-line-height;
  margin-bottom: 0px;
  text-align: start;
}

.label.ant-typography {
  color: @text-color;
  font-size: @font-size-base;
  line-height: @line-height-base;
  margin-bottom: 0px;
  margin-left: 8px;
}

.subTitle.ant-typography {
  color: @text-color-dark;
  font-weight: @font-weight-medium;
  font-size: @font-size-sm;
  line-height: @line-height-base;
  margin-bottom: 0px;
  text-align: left;
}

.description.ant-typography {
  color: @text-color;
  font-weight: @font-weight-medium;
  font-size: @font-size-sm;
  line-height: @line-height-base;
  text-align: left;
}

.card-clock {
  color: #1890ff;
  font-size: 20px;
}

.links {
  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-form-item-control-input {
    min-height: unset;
  }
}

.action {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.modalFooter {
  margin-bottom: 0px;
}

.dateValue {
  font-size: @font-size-sm;
  font-weight: @font-weight-medium;
}

.rangePicker {
  height: 32px;
  border: 1px solid @border-color;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;

  &.ant-btn {
    padding: 2px 24px;
  }
}

.form.ant-form-item .ant-input-group .ant-select {
  width: 100px;
}

.sortableList {
  overflow-y: auto;
  max-height: calc(100vh - 152px);
}

.row.ant-row {
  width: 100vw;
}

.links-Wrapper {
  height: calc(100vh - 57px);
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    overflow: scroll;
  }
}

.draggable {
  text-align: left;
}

.links-list {
  overflow: scroll;
  height: 100%;
}

.empty-text {
  font-size: @h4-font-size;
  color: @text-color-light;
  font-weight: @font-weight-semi-bold;
}

.add-links.ant-col-11 {
  max-width: 500px;
  min-width: 500px;
  transition: all 0.3s;
  @media only screen and (max-width: 1050px) {
    min-width: 420px;
    transition: all 0.3s;
  }

  @media only screen and (max-width: 471px) {
    min-width: 330px;
    transition: all 0.3s;
  }
}
