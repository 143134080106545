@import '../global/variables.less';

.appearance-wrapper {
  height: calc(100vh - 57px);
  overflow: hidden;
  @media only screen and (max-width: 1000px) {
    overflow-y: scroll;
  }
}
.appearance-list {
  overflow-y: scroll;
  height: 100%;
}
